import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#241F19',
    [Prop.CONTENT]: '#241F19',
    [Prop.LINK_TXT]: '#00b6ea',
    [Prop.LINK_TXT_HOVER]: '#E947B6',
    [Prop.BTN_BG]: '#00b6ea',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#E947B6',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#00b6ea',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#241F19',
    [Prop.BTN_TXT_INACTIVE]: '#EBEFF5',
    [Prop.ELEMENT]: '#00b6ea',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#241F19',
    [Prop.ICON]: '#00b6ea',
    [Prop.ICON_HOVER]: '#E947B6',
    [Prop.ICON_2]: '#00b6ea',
    [Prop.ICON_HOVER_2]: '#241F19',
    [Prop.HEADER_BG]: '#00b6ea',
    [Prop.HEADER_TXT]: '#241F19',
    [Prop.HEADER_ICON]: '#241F19',
    [Prop.HEADER_BG_2]: '#00b6ea',
    [Prop.HEADER_TXT_2]: '#241F19',
    [Prop.HEADER_ICON_2]: '#241F19',
    [Prop.ODD_EVEN_OFFSET]: '#E5EDED',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#00b6ea',
    [Prop.HEADER]: '#241F19',
    [Prop.CONTENT]: '#241F19',
    [Prop.LINK_TXT]: '#241F19',
    [Prop.LINK_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG]: '#FFFFFF',
    [Prop.BTN_TXT]: '#241F19',
    [Prop.BTN_BG_HOVER]: '#E947B6',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#FFFFFF',
    [Prop.BTN_TXT_ACTIVE]: '#241F19',
    [Prop.BTN_BG_INACTIVE]: '#241F19',
    [Prop.BTN_TXT_INACTIVE]: '#EBEFF5',
    [Prop.ELEMENT]: '#FFFFFF',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#241F19',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#241F19',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#241F19',
    [Prop.HEADER]: '#00b6ea',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#00b6ea',
    [Prop.LINK_TXT_HOVER]: '#E947B6',
    [Prop.BTN_BG]: '#00b6ea',
    [Prop.BTN_TXT]: '#241F19',
    [Prop.BTN_BG_HOVER]: '#FFFFFF',
    [Prop.BTN_TXT_HOVER]: '#E947B6',
    [Prop.BTN_BG_ACTIVE]: '#00b6ea',
    [Prop.BTN_TXT_ACTIVE]: '#241F19',
    [Prop.BTN_BG_INACTIVE]: '#EBEFF5',
    [Prop.BTN_TXT_INACTIVE]: '#241F19',
    [Prop.ELEMENT]: '#00b6ea',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#00b6ea',
    [Prop.ICON]: '#00b6ea',
    [Prop.ICON_HOVER]: '#E947B6',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#EBEFF5',
    [Prop.HEADER]: '#241F19',
    [Prop.CONTENT]: '#241F19',
    [Prop.LINK_TXT]: '#241F19',
    [Prop.LINK_TXT_HOVER]: '#00b6ea',
    [Prop.BTN_BG]: '#00b6ea',
    [Prop.BTN_TXT]: '#241F19',
    [Prop.BTN_BG_HOVER]: '#E947B6',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#00b6ea',
    [Prop.BTN_TXT_ACTIVE]: '#241F19',
    [Prop.BTN_BG_INACTIVE]: '#241F19',
    [Prop.BTN_TXT_INACTIVE]: '#EBEFF5',
    [Prop.ELEMENT]: '#00b6ea',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#241F19',
    [Prop.ICON]: '#241F19',
    [Prop.ICON_HOVER]: '#00b6ea',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#241F19',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#E947B6',
    [Prop.LINK_TXT_HOVER]: '#00b6ea',
    [Prop.BTN_BG]: '#E947B6',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#00B6EA',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#E947B6',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#EBEFF5',
    [Prop.BTN_TXT_INACTIVE]: '#241F19',
    [Prop.ELEMENT]: '#E947B6',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.BORDER]: '#E947B6',
    [Prop.ICON]: '#E947B6',
    [Prop.ICON_HOVER]: '#00b6ea',
  },
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.NAV_BG]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#241F19',
    [Prop.NAV_ITEM_TXT_SOLID]: '#241F19',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#241F19',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#00b6ea',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#00b6ea',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },
  [Components.MOBILE_NAV]: {
    [Prop.MENU_BTN_BG]: '#FFFFFF',
    [Prop.MENU_BTN_ICON]: '#00b6ea',
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.CLOSE_BTN_BG]: '#00b6ea',
    [Prop.CLOSE_BTN_ICON]: '#241F19',
    [Prop.MAIN_NAV_BG]: '#FFFFFF',
    [Prop.MAIN_NAV_TXT]: '#241F19',
    [Prop.MAIN_NAV_BORDER]: '#241F19',
    [Prop.WW_ICON_COLOR]: '#FFD200',
    [Prop.DRAWER_BTN_BG]: '#FFFFFF',
    [Prop.DRAWER_BTN_TXT]: '#00b6ea',
    [Prop.PROMO_BTN_BG]: '#FFFFFF',
    [Prop.PROMO_BTN_TXT]: '#241F19',
    [Prop.SUB_NAV_BG]: '#EBEFF5',
    [Prop.SUB_NAV_TXT]: '#241F19',
    [Prop.SUB_NAV_BORDER]: '#241F19',
  },
};
