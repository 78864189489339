import { mergeDeepLeft } from 'ramda';

import {
  FontSize as FontSizes, FontFamilies,
} from '@powdr/constants';

/** ***************************************** */
/* Default Font Overrides */
/** ***************************************** */
const FontSizeOverrides = {
  // EXAMPLE: reg30: '100px',
};

const FontSize = mergeDeepLeft(FontSizeOverrides, FontSizes);

const fonts = {
  [FontFamilies.PRI_HDR_BLK]: 'font-family: "Antonio Bold"',
  [FontFamilies.SEC_HDR_BLK]: 'font-family: "Antonio Bold"',
  [FontFamilies.PRI_BDY_HVY]: 'font-family: "Gotham Bold"',
  [FontFamilies.PRI_BDY_MED]: 'font-family: "Gotham Book"',
};

export const fontTheme = {
  fonts,
  FontSize,
};
