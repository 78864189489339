import { ThemeProps as Prop } from '@powdr/constants';

export const StaticTheme = {
  colors: {
    [Prop.BLACK]: '#000000',
    [Prop.WHITE]: '#FFFFFF',
  },
  shades: {
    [Prop.LIGHTEST]: '#F0F0F0',
    [Prop.LIGHTER]: '#E0E0E0',
    [Prop.LIGHT]: '#CCCCCC',
    [Prop.GRAY]: '#8B8B8B',
    [Prop.GRAYER]: '#696969',
    [Prop.GRAYEST]: '#525257',
    [Prop.DARK]: '#333333',
    [Prop.DARKER]: '#222222',
    [Prop.DARKEST]: '#292929',
  },
  difficulty: {
    [Prop.BEGINNER]: '#73C053',
    [Prop.INTERMEDIATE]: '#30A8E0',
    [Prop.EXPERT]: '#000000',
  },
  social: {
    [Prop.FACEBOOK]: '#3B5998',
    [Prop.EVENTBRITE]: '#F6682F',
  },
  alerts: {
    [Prop.ACTIVE]: '#2176b8',
    [Prop.SUCCESS]: '#73c053',
    [Prop.INFO]: '#0b24fb',
    [Prop.WARNING]: '#fffd38',
    [Prop.DANGER]: '#fc0d1b',
  },
  parkingCal: {
    [Prop.SECTION_BG]: '#f0f0f0',
    [Prop.SECTION_TXT]: '#4D5859',
    [Prop.ITEM_BG]: '#DCDCDC',
    [Prop.ITEM_TXT]: '#4D5859',
    [Prop.ITEM_TXT_HOVER]: '#00AEBB',
    [Prop.ITEM_BG_NO_INTERACT]: '#ECECEC',
    [Prop.ITEM_TXT_NO_INTERACT]: '#B2B6B5',
    [Prop.ITEM_BG_AVAILABLE]: '#00AEBB',
    [Prop.ITEM_TXT_AVAILABLE]: '#FFFFFF',
    [Prop.ITEM_BTN_BG_AVAILABLE]: '#D4441C',
    [Prop.ITEM_BTN_TXT_AVAILABLE]: '#FFFFFF',
    [Prop.ITEM_BG_SELECTED]: '#126978',
    [Prop.ITEM_TXT_SELECTED]: '#FFFFFF',
    [Prop.ITEM_BTN_BG_SELECTED]: '#D4441C',
    [Prop.ITEM_BTN_TXT_SELECTED]: '#FFFFFF',
    [Prop.RES_ITEM_CONTAINER_BG]: '#FFFFFF',
  },
  tv: {
    [Prop.BACKGROUND]: '#234C5B',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.BORDER]: '#FFFFFF',
    [Prop.ICON]: '#FFFFFF',
    [Prop.LINK]: '#FFFFFF',
    [Prop.LINK_HOVER]: '#D4441C',
    [Prop.ODD_EVEN_OFFSET]: '#E5EDED',
    [Prop.SIDE_NAV_ITEM_BG]: '#D4441C',
    [Prop.SIDE_NAV_ITEM_TXT]: '#FFFFFF',
    [Prop.SIDE_NAV_ITEM_ICON]: '#FFFFFF',
    [Prop.SIDE_NAV_ITEM_BG_ACTIVE]: '#E9792C',
    [Prop.SIDE_NAV_ITEM_TXT_ACTIVE]: '#FFFFFF',
    [Prop.SIDE_NAV_ITEM_ICON_ACTIVE]: '#FFFFFF',
    [Prop.TOP_NAV_ITEM_TXT]: '#FFFFFF',
    [Prop.ALERT_MARQUEE_BG]: '#5c7484',
    [Prop.ALERT_MARQUEE_ALERT_TXT]: '#FFFFFF',
    [Prop.ALERT_MARQUEE_TEMP_TIME_TXT]: '#FFFFFF',
    [Prop.ALERT_OVERLAY_BG]: '#234C5B',
    [Prop.ALERT_OVERLAY_TXT]: '#FFFFFF',
    [Prop.ALERT_OVERLAY_TEMP_TIME_TXT]: '#FFFFFF',
    [Prop.LIFTS_TXT]: '#FFFFFF',
    [Prop.LIFTS_ICON]: '#FFFFFF',
    [Prop.EVENT_1]: '#FFFFFF',
    [Prop.EVENT_2]: '#FFFFFF',
  },
};
